import React, { useContext } from "react";
import InnerHeadr from "../components/InnerHeader";
import PageHeader from "../components/PageHeader";
import { ThemeContext } from "../layout/Layout";
import Marquee from "../components/Marquee";

const pageDetails = {
  head: {
    upper: "EMPOWERING BUSINESSES W/",
    lower: "Digital Experiences",
  },
  bottom: {
    normal: "SCALE YOUR BUSINESS ",
    italic: (
      <>
        THROUGH DATA DRIVEN <br /> DIGITAL CULTURE
      </>
    ),
  },
};

const services = [
  { title: "Digital Branding", detail: "Build real connections with your customers allowing for meaningful messages that have a long-lasting impact." },
  { title: "UI/UX Design", detail: "Client-centric designs that adapt. Designed for an exceptional and engaging user experience for the web and all mobile platforms." },
  { title: "ECommerce Design", detail: "Scalable and customer-focused ecommerce architecture. Adaptive designs tailored to your specific business model and audience." },
  { title: "Web Development", detail: "Full-stack developers ready for all complex web solutions. Utilizing the newest web strategies and frameworks for today's internet standards." },
  { title: "Ecommeerce Development", detail: "Empower your online business with the newest technologies and solutions. Optimized shopping experiences made to scale for your customers." },
  { title: "Research and Strategy", detail: "No more consulting from a distance. We want to get to know the ins and outs of your business, product, team, and vision so that we can help your business transform with a lasting impact." },
  { title: "No Code Develpoment", detail: "Full-service Webflow development for businesses across all industries. Take your business to the next level with a customized Webflow website." },
  { title: "App Develpoment", detail: "Full-service Webflow development for businesses across all industries. Take your business to the next level with a customized Webflow website." },
  { title: "Design System", detail: "Full-service Webflow development for businesses across all industries. Take your business to the next level with a customized Webflow website." },
];

const Service = ({ title, detail, theme }) => {
  return (
    <>
      <div className="flex  items-center h- justify-between lg:my-16 my-8 flex-col">
        <div className="flex flex-col lg:flex-row items-start justify-between lg:p-10 w-full">
          <div className="lg:w-3/4 w-full flex flex-col items-start justify-between">
            <p className="text-5xl helv uppercase lg:w-1/2 font-semibold">{title}</p>
            {/* <p className="italic my-8 text-primary-100 font-light  uppercase">Find Out More</p> */}
          </div>
          <div className="lg:w-2/4 text-2xl">{detail}</div>
        </div>
        <hr className={`w-full h-0 mx-8 my-4 border-t mb-2 ${theme === "dark" ? "border-white" : "border-black"}`} />
      </div>
    </>
  );
};

export default function Services() {
  const theme = useContext(ThemeContext);

  return (
    <>
      <div className="w-screen h-screen relative">
        <PageHeader title="Services" />
        <InnerHeadr head={pageDetails.head} bottom={pageDetails.bottom} />
      </div>
      <div className="w-screen minh-screen relative">
        <div className={`container sticky top-0 mx-auto flex flex-col ${theme === "dark" ? "bg-black" : "bg-white"}`}>
          <div className="flex items-center px-8 text-lg lg:text-2xl py-4 lg:py-8 justify-between">
            <div className="flex items-center justify-center">
              <div className="w-2 h-2 bg-primary-100 rounded-full"></div> <p className="ml-3 italic radio text-primary-100 capitalize "> Our Services</p>
            </div>
            <div className="flex items-center justify-center">
              <p className="helv capitalize ">Request A Quote</p>
              {/* <div className="w-10 h-10 rounded-full ml-4 flex  items-center justify-center"></div> */}
            </div>
          </div>
          <hr className={`w-full h-0 border-t mb-2 ${theme === "dark" ? "border-white" : "border-black"}`} />
        </div>

        <div className="container px-8 mx-auto grid lg:grid-cols-4 grid-cols-1 ">
          <div className="flex mb-20 col-span-1 lg:sticky h-1 pt-20 top-20 ">
            <div className="flex flex-col text-xl lg:pr-32 text-primary-100 italic uppercase ">Multi-Faceted Services For Your Growth and Hypergrowth</div>
          </div>
          <div className="pt-0 lg:col-span-3 col-span-1 h-2fifthvh">
            {services.map((service, index) => {
              return <Service key={index} title={service.title} detail={service.detail}></Service>;
            })}
          </div>
        </div>
        <div className="w-screen relative h-screen"></div>
        <Marquee cont="SERVICES" />
      </div>
    </>
  );
}
